// 
// custom.scss
//

@import "../../config/tandem/variables";

body {
  font-family: "Raleway", sans-serif;
}

.wrapper, body, html, .content-page {
  overflow: unset;
}

.text-fuchsia {
  color: $tandemPurple;
}

.text-green {
  color: $tandemGreen;
}

.rbt-aux {
  position: absolute;
  top: 8px;
  right: 8px;
}

.btn-icon {
  padding: 0 0.3em;
}

.btn-icon i {
  font-size: 1.5em;
}

.btn-fuchsia:not(:disabled):not(.disabled):active:focus,
.btn-fuchsia:not(:disabled):not(.disabled).active:focus,
.show > .btn-fuchsia.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(190,104,202,0.5);
}

.btn-fuchsia {
   color: #fff;
   background-color: $tandemPurple;
   border-color: $tandemPurple;
}

.btn-fuchsia:hover {
  color: #fff;
  background-color: $tandemPurple;
  border-color: $tandemPurple;
}

.nav-user {
  background-color: transparent;
  border: none;
  min-height: 70px;
}
.nav-user .account-user-name {
  font-weight: normal;
}

.preloader {
  z-index: 999;
}

.table-icons {
  width: 1%;
  white-space: nowrap;
}

.react-bootstrap-table .table td {
  vertical-align: middle;
}

.table .thead-green th {
  color: $tandemGreen;
  background-color: $tandemLightGreen;
  border-color: #FFF;
}

.table.savings-table td, .table.savings-table th {
  vertical-align: middle;
}

.table.table-reduced td, .table.table-reduced th {
  font-size: 85%;
  padding: 3px 5px;
}

// Custom File Input
.custom-file-label::after {
  font-family: dripicons-v2;
  content: "\e041" !important;
}

// WYSIWYG
.rdw-editor-main {
  padding: 1px 5px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  min-height: 300px;
}

.rdw-editor-toolbar {
  padding: 0 !important;
  border: none !important;
}

.rdw-option-wrapper {
  border: 1px solid #dee2e6 !important;
  padding: 11px 5px !important;
  border-radius: 0.25rem !important;
  margin: 0 2px !important;
  background: #ffffff !important;
  min-width: 30px !important;
  min-height: 30px !important;
}

.rdw-option-wrapper:hover, .rdw-option-wrapper.rdw-option-active {
  box-shadow: none;
  background-color: #f3f3f3 !important;
}

.topbar-nav .side-nav-link {
  padding: 1em 0.4em;
  white-space: normal !important;
}

.side-nav .side-nav-link span {
  display: block;
  font-size: 0.9em;
  line-height: 1.2em;
  padding-top: 0.5em;
}

.topbar-nav .side-nav-link i {
  margin: 0;
  font-size: 3em;
  display: inline;
}


.font-2rem {
  font-size: 2rem;
}

.font-3rem {
  font-size: 3rem;
}

.font-4rem {
  font-size: 4rem;
}

.btn-xs, .btn-group-xs > .btn {
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  line-height: 1.4;
  border-radius: 0.15rem;
}

.extraCondensed th, .extraCondensed td {
  padding: 0.3rem;
  font-size: 12px;
  vertical-align: middle;
}

.extraCondensed .btn-icon i {
  font-size: 1.2em;
}

.extraCondensed .form-group {
  margin-bottom: 0;
}

.extraCondensed input.form-control.date {
  padding: 2px;
  height: 25px;
  font-size: 1em;
  width: 70px;
  text-align: center;
}

.extraCondensed input.form-control.input, .extraCondensed select {
  padding: 2px;
  height: 25px !important;
  font-size: 0.9em;
}

.form-group-mb0 .form-group {
  margin-bottom: 0;
}

.icon-box {
  opacity: 0.5;
  margin-top: -1.5rem;
}

.workerDropdown, .companyDropdown {
  padding-left: calc(31px / 2)!important;
}

.topnav-logo-lg img {
  height: 40px;
}

.topbar-nav .side-nav-link i.t-icon,
.card i.t-icon, .hasIcon i.t-icon {
  width: 1em;
  height: 1.2em;
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: auto;
}

body[data-layout="detached"] .side-nav-link i.t-icon,
body[data-layout="vertical"] .side-nav-link i.t-icon {
  width: 2em;
  height: 2em;
  margin-left: 0!important;
  display: inline-block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

body[data-layout="detached"] .side-nav .side-nav-link span,
body[data-layout="vertical"] .side-nav .side-nav-link span{
  display: inline-block;
}

.apex-charts text {
  font-family: "Raleway", sans-serif !important;
  fill: #adb5bd;
}

.card i.t-icon, .hasIcon i.t-icon {
  height: 90px;
  width: 90px;
  background-size: initial;
  border: 1px solid $tandemGreen;
  border-radius: 50%;
}

.card i.t-icon-highlighted, .hasIcon i.t-icon-highlighted {
  background-color: $tandemLightGreen;
}
.card-selected p {
  font-weight: bold;
}

.card-selected i.t-icon {
  border: 4px solid $tandemPurple;
}

.card.has-left-icon, .hasIcon.has-left-icon {
  padding-left: 80px;
}

.card i.t-icon.t-icon-left, .hasIcon i.t-icon.t-icon-left {
  margin: 0;
  height: 48px;
  width: 48px;
  float: left;
  background-size: 32px;
}

.card i.t-icon.in-cell-icon, .hasIcon i.t-icon.in-cell-icon {
  height: 32px;
  width: 32px;
  background-size: 18px;
}

.card.has-left-icon i.t-icon, .hasIcon.has-left-icon i.t-icon {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -48px;
  border: none
}

.card i.t-icon.t-icon-sm, .hasIcon i.t-icon.t-icon-sm {
  height: 45px;
  width: 45px;
  background-size: 70% !important;
}

.card i.t-icon.t-icon-md, .hasIcon i.t-icon.t-icon-md {
  height: 66px;
  width: 66px;
  background-size: 70% !important;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: $tandemPrimary;
}

.t-icon-home {
  background-image: url("../../../images/icons/home-64.svg");
}

.t-icon-organization {
  background-image: url("../../../images/icons/servicios-tg-64.svg");
}

.t-icon-supplier {
  background-image: url("../../../images/icons/sincro-64.svg");
}

.t-icon-invoice {
  background-image: url("../../../images/icons/facturacio-64.svg");
}

.t-icon-worker {
  background-image: url("../../../images/icons/grup-persones-64.svg");
}

.t-icon-document {
  background-image: url("../../../images/icons/documents-64.svg");
}

.t-icon-settings {
  background-image: url("../../../images/icons/configuracio-64.svg");
}

.t-icon-transport {
  background-image: url("../../../images/icons/bus-64.svg");
}

.t-icon-restaurant {
  background-image: url("../../../images/icons/restaurant-64.svg");
}

.t-icon-kindergarten {
  background-image: url("../../../images/icons/kindergarten-64.svg");
}

.t-icon-trainingCenter {
  background-image: url("../../../images/icons/training-64.svg");
}

.t-icon-insurance {
  background-image: url("../../../images/icons/insurance-64.svg");
}

.t-icon-cards {
  background-image: url("../../../images/icons/cards-64.svg");
}

.t-icon-service {
  background-image: url("../../../images/icons/servicios-tg-64.svg");
}

.t-icon-demand {
  background-image: url("../../../images/icons/verificar-64.svg");
}

.t-icon-payroll {
  background-image: url("../../../images/icons/calculadora-64.svg");
}

.t-icon-mobile {
  background-image: url("../../../images/icons/seleccionar-64.svg");
}

.t-icon-email {
  background-image: url("../../../images/icons/enviar-64.svg");
}

.t-icon-thumbs {
  background-image: url("../../../images/icons/thumbs-up-64.svg");
}

.t-icon-selection {
  background-image: url("../../../images/icons/mi-seleccion-64.svg");
}

.t-icon-arrow-up {
  background-image: url("../../../images/icons/arrow-up.svg");
}

.t-icon-arrow-down {
  background-image: url("../../../images/icons/arrow-down.svg");
  background-size: 64px!important;
}

.t-icon-arrow-sideways {
  background-image: url("../../../images/icons/arrow-sideways.svg");
  background-size: 64px!important;
}

.t-icon-pay {
  background-image: url("../../../images/icons/mi-tarjeta-64.svg");
  background-size: 48px;
}

.t-icon-customize {
  background-image: url("../../../images/icons/disseny-64.svg");
  background-size: 64px!important;
}

.t-icon-data {
  background-image: url("../../../images/icons/dades-64.svg");
  /* background-size: 64px!important; */
}

.t-icon-lock {
  background-image: url("../../../images/icons/password-64.svg");
  background-size: 64px!important;
}

.t-icon-key {
  background-image: url("../../../images/icons/clau-64.svg");
  background-size: 64px!important;
}

.t-icon-simulate {
  background-image: url("../../../images/icons/simular-mi-ahorro-64.svg");
  background-size: 48px;
}

.t-icon-envelope {
  background-image: url("../../../images/icons/correu-64.svg");
}

.t-icon-whatsapp {
  background-image: url("../../../images/icons/whatsapp-64.svg");
}

.t-icon-heart-on-palm {
  background-image: url("../../../images/icons/ma-cor-2-64.svg");
}

.t-icon-search {
  background-image: url("../../../images/icons/lupa-64.svg");
}

.t-icon-child {
  background-image: url("../../../images/icons/filla-64.svg");
}

.t-icon-user {
  background-image: url("../../../images/icons/usuari-64.svg");
}

.t-icon-man {
  background-image: url("../../../images/icons/noi-64.svg");
}

.t-icon-woman {
  background-image: url("../../../images/icons/noia-64.svg");
}

.t-icon-ess {
  background-image: url("../../../images/icons/planta-64.svg");
}

.t-icon-health {
  background-image: url("../../../images/icons/ma-cor-2-64.svg");
}

.t-icon-insurances {
  background-image: url("../../../images/icons/cobertura-64.svg");
}

.t-icon-supplies {
  background-image: url("../../../images/icons/sincro-64.svg");
}

.t-icon-smiley {
  background-image: url("../../../images/icons/smiley-64.svg");
}

.t-icon-puzzle {
  background-image: url("../../../images/icons/cor-puzzle-64.svg");
}

.t-icon-savings {
  background-image: url("../../../images/icons/estalvi-guardiola-64.svg");
}

.t-icon-search {
  background-image: url("../../../images/icons/lupa-64.svg");
}

.t-icon-payment {
  background-image: url("../../../images/icons/cards-64.svg");
}

.t-icon-calendar {
  background-image: url("../../../images/icons/calendari-64.svg");
}

.t-icon-faq {
  background-image: url("../../../images/icons/faqs-64.svg");
}

ul.arrowed {
  list-style-type: none;
  padding-left: 0;
}

ul.arrowed li {
  display: grid;
  grid-template-columns: 30px auto;
  justify-content: start;
  align-items: center;
}

ul.arrowed li::before {
  font-family: "Material Design Icons" ;
  content: "\F057";
  font-size: 20px;
  color: $tandemGreen;
}

.react-bootstrap-table thead th {
  background-color: $tandemLightGreen;
  color: $tandemGreen;
  border-color: #FFF;
}

.react-bootstrap-table-pagination {
  margin-top: 15px;
}

/* headlines with lines */
.decorated{
  overflow: hidden;
  text-align: center;
}
.decorated > span{
  position: relative;
  display: inline-block;
}
.decorated > span:before, .decorated > span:after{
  content: '';
  position: absolute;
  top: 50%;
  border-bottom: 1px solid;
  width: 100vw;
  margin: 0 20px;
}
.decorated > span:before{
  right: 100%;
}
.decorated > span:after{
  left: 100%;
}

.tandem-slider.slider.noUi-horizontal {
  height: 15px!important;
  border: 1px solid #ddd!important;
}

.tandem-slider.slider .noUi-connect {
  background-color: #f0f0f0!important;
}

.tandem-slider.slider.noUi-horizontal.noUi-target {
  background-color: rgb(252,240,254)!important;
}

.tandem-slider.slider.noUi-horizontal .noUi-handle {
  background-color: #cc88d5!important;
  height: 30px!important;
  width: 30px!important;
  border-radius: 50%;
  box-shadow: 0 1px 13px 0 rgba(0,0,0,2);
  cursor: pointer;
  outline: 0;
}

.tandem-slider.slider.noUi-horizontal .noUi-handle:after, .tandem-slider.slider.noUi-horizontal .noUi-handle:before {
  display: none;
}

.tandem-totals.card {
  display: inline;
  padding: 5px 10px;
  border: 1px solid #5a8b8d;
}

.vh-25 {
  max-height: 25vh;
  overflow: auto;
}

.pointer {
  cursor: pointer;
}

.timeline-alt .timeline-item:last-child:before {
  width: 0;
}

.PhoneInputInput {
  border: none!important;
  color: #727272!important;
}

.indent dd {
  padding-left: 1rem;
}

@media (min-width: 992px) {
  .topbar-nav .side-nav-link {
    text-align: center;
  }

  .processLine .processLine-item:before {
    border-bottom: 1px dashed $tandemPurple;
    bottom: 0;
    content: "";
    top: 35px;
    position: absolute;
    left: 50%;
    margin-left: 20%;
    width: 60%;
    z-index: 0;
    height: 3px;
  }

  .processLine.processLine-primary .processLine-item:before {
    border-bottom: 1px dashed $tandemPrimary;
  }

  .processLine.condensed .processLine-item:before {
    top: 24px;
    height: 2px;
  }

}

.hasSweetAlert div {
  display: inline;
}

.label-none.form-check-input {
  display: inherit;
}

.toast-wrapper {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 9999;
}

/* for sm */

.custom-switch.custom-switch-sm .custom-control-label {
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.custom-switch.custom-switch-sm .custom-control-label::before {
  height: 1rem;
  width: calc(1rem + 0.75rem);
  border-radius: 2rem;
}

.custom-switch.custom-switch-sm .custom-control-label::after {
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  border-radius: calc(1rem - (1rem / 2));
}

.custom-switch.custom-switch-sm .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(calc(1rem - 0.25rem));
}

/* for md */

.custom-switch.custom-switch-md .custom-control-label {
  padding-left: 2rem;
  padding-bottom: 1.5rem;
}

.custom-switch.custom-switch-md .custom-control-label::before {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.custom-switch.custom-switch-md .custom-control-label::after {
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px);
  border-radius: calc(2rem - (1.5rem / 2));
}

.custom-switch.custom-switch-md .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(calc(1.5rem - 0.25rem));
}

/* for lg */

.custom-switch.custom-switch-lg .custom-control-label {
  padding-left: 3rem;
  padding-bottom: 2rem;
}

.custom-switch.custom-switch-lg .custom-control-label::before {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.custom-switch.custom-switch-lg .custom-control-label::after {
  width: calc(2rem - 4px);
  height: calc(2rem - 4px);
  border-radius: calc(3rem - (2rem / 2));
}

.custom-switch.custom-switch-lg .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(calc(2rem - 0.25rem));
}

/* for xl */

.custom-switch.custom-switch-xl .custom-control-label {
  padding-left: 4rem;
  padding-bottom: 2.5rem;
}

.custom-switch.custom-switch-xl .custom-control-label::before {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}

.custom-switch.custom-switch-xl .custom-control-label::after {
  width: calc(2.5rem - 4px);
  height: calc(2.5rem - 4px);
  border-radius: calc(4rem - (2.5rem / 2));
}

.custom-switch.custom-switch-xl .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(calc(2.5rem - 0.25rem));
}

.font-weight-normal label {
  font-weight: normal!important;
}

.disabled-row td {
  background-color: #f9f9f9;
  color: #b9b9b9;
}

.video-wrapper {
  position: relative;
  height: 430px;
}

.essServiceHeader {
  height: 100px;
  /* vertical-align: middle;
  text-align: center; */
}

.essServiceHeader img {
  max-height:100%;
}

.rbt-input-multi .form-control .rbt-input {
  height: auto;
}

.rbt-token {
  display: inline-block;
  padding: 0px 10px 0px 10px;
  border-radius: 4px;
  margin-right: 5px;
}

.rbt-token button {
  padding-left: 10px;
}

.custom-switch.custom-switch-lg .custom-control-label {
  padding-bottom: 2rem !important;
  padding-top: 0.5rem !important;
}

.big-custom-switch.custom-switch .custom-control-label::before {
  left: -4rem;
  width: 3.5rem;
  height: 2rem;
  border-radius: 1.5rem;
}

.big-custom-switch.custom-switch .custom-control-label::after {
  width: calc(2rem - 4px);
  height: calc(2rem - 4px);
  left: calc(-4rem + 2px);
  border-radius: 1.5rem;
}

.big-custom-switch.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  -webkit-transform: translateX(1.5rem);
  transform: translateX(1.5rem);
}

.max-width-400 {
  max-width: 400px;
}

.max-width-300 {
  max-width: 300px;
}

.max-width-200 {
  max-width: 200px;
}

.max-width-150 {
  max-width: 150px;
}

.account-user-avatar .noti-icon {
  line-height: 30px !important;
}

.nav-user .account-user-avatar {
  left: 0 !important;
}

.customLogo {
  height: auto !important;
  width: 100%;
  max-width: 150px;
  border-left: 1px solid #e2e2e2;
}

.custom-radio.custom-control {
  margin-bottom: 10px;
}

.card-number {
  z-index: 999;
  position: absolute;
  top: 0;
  margin-top: -15px;
  left: -70px;
  text-align: center;
}

.table-vertical th,
.table-vertical td {
  vertical-align: middle;
}

.btn-danger, .btn-success {
  box-shadow: none !important;
}

.input-icon {
  position: absolute;
  right: 0.3em;
  top: 1.5em;
  font-size: 1.5em;
}

.tg-inner-nav .nav-item {
  padding-bottom: 2px
}

.tg-inner-nav.nav-tabs .nav-link.active,
.tg-inner-nav.nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #5a8b8d;
}

@media (max-width: 766px) {

  .card-number {
    position: relative;
    width: 100%;
    left: 0;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 90%;
  }

  .modal-dialog.modal-md {
    max-width: 60% !important;
  }

}

.alert-primary {
  color: $tandemGreen;
  background-color: #e5f2f3;
  border-color: #d1dfdf;
}

.nav-user {
  min-height: 76px;
}

.topnav-navbar .topnav-logo {
  padding-right: 0;
}

.topnav-navbar .navbar-toggle {
  margin: 29px 15px 25px 15px;
}

.metismenu li {
  max-width: 100px;
}

@media (max-width: 992px) {

  .metismenu li {
    max-width: 100%;
  }

  .topbar-nav .side-nav-link span {
    display: inline;
    vertical-align: super;
    font-size: 1rem;
    margin-left: 0.3rem;
  }

  .topbar-nav .side-nav-link i.t-icon {
    display: inline-block;
    height: 0.7em;
  }

  body[data-layout="topnav"] .navbar-custom .button-menu-mobile.disable-btn {
    position: absolute;
    left: 0;
    opacity: 0;
  }
}