@font-face {
  font-family: 'Raleway';
  src: url('../../../fonts/raleway/Raleway-VariableFont_wght.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../../../fonts/raleway/Raleway-VariableFont_wght.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../../../fonts/raleway/Raleway-VariableFont_wght.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../../../fonts/raleway/Raleway-VariableFont_wght.ttf');
  font-weight: 700;
  font-style: normal;
}