// Styles

:root {
  --color1: #5a8b8d; // Green
  --color2: #be68ca; // Fuchsia
  --color3: #fafbfe; // Background
  --color4: #e5f2f3; // TopNav
}

body, .navbar-custom {
  background-color: var(--color3);
}

.topnav, .bg-tandem {
  background-color: var(--color4);
}

.btn-primary {
  box-shadow: 0 0 0 var(--color1);
}

.btn-primary {
  color: #fff;
  background-color: var(--color1);
  border-color: var(--color1);
}

.btn-fuchsia {
  color: #fff;
  background-color: var(--color2);
  border-color: var(--color2);
}

.card i.t-icon {
  border: 1px solid var(--color4);
  background-color: var(--color4);
}

.table .thead-green th {
  color: var(--color1);
  background-color: var(--color4);
  border-color: #FFF;
}

.text-primary, .text-green, .side-nav .side-nav-link {
  color: var(--color1) !important;
}

.text-fuchsia {
  color: var(--color2);
}
